import { Hreflang } from '@utils/hreflang';
import Head from 'next/head';
import React from 'react';

type PropsType = {
    defaultLocale: string;
    companySlug: string;
    refs: Hreflang[];
};

const HreflangHead: React.FC<PropsType> = ({
    refs,
    defaultLocale,
    companySlug,
}) => {
    return (
        <Head>
            {refs.map(({ locale, path }) => {
                if (locale == defaultLocale) {
                    return (
                        <React.Fragment key={locale}>
                            <link
                                key={'default'}
                                rel='alternate'
                                hrefLang='x-default'
                                href={`https://${companySlug}.pimster.app/${locale}${path}`}
                            />
                            <link
                                key={locale}
                                rel='alternate'
                                hrefLang={locale}
                                href={`https://${companySlug}.pimster.app/${locale}${path}`}
                            />
                        </React.Fragment>
                    );
                } else {
                    return (
                        <link
                            key={locale}
                            rel='alternate'
                            hrefLang={locale}
                            href={`https://${companySlug}.pimster.app/${locale}${path}`}
                        />
                    );
                }
            })}
        </Head>
    );
};

export default HreflangHead;
