import { LockedIcon } from '@components/utils/icons/LockedIcon';
import { IconPastille, PastilleProps } from '@components/utils/icons/pastille';
import styled from '@emotion/styled';

interface Props extends PastilleProps {
    isLocked?: boolean;
}

const IconWrapper = styled.div`
    position: relative;
    width: fit-content;
`;

const LockedIconContainer = styled.div`
    position: absolute;
    right: -0.5rem;
    bottom: -0.5rem;
`;

export const ModuleIcon = ({ isLocked, ...pastilleProps }: Props) => {
    return (
        <IconWrapper>
            <IconPastille {...pastilleProps} />
            {isLocked && (
                <LockedIconContainer>
                    <LockedIcon />
                </LockedIconContainer>
            )}
        </IconWrapper>
    );
};
