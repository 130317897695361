import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
    isFromAssistant?: boolean;
}

export const MessageBoxWrapper = styled.div<StyledProps<Props>>`
    width: fit-content;
    overflow-x: scroll;
    max-width: 90%;
    margin-left: ${({ isFromAssistant }) =>
        !isFromAssistant ? 'auto' : undefined};
    margin-right: ${({ isFromAssistant }) =>
        isFromAssistant ? 'auto' : undefined};
`;
