import { MediaFragment } from '@graphql/generated/graphql';
import { ReactNode, isValidElement } from 'react';

export const isValidSuggestion = (
    suggestion: unknown
): suggestion is string => {
    return typeof suggestion === 'string';
};

export const areValidSuggestions = (
    suggestions: unknown
): suggestions is string[] => {
    if (!Array.isArray(suggestions)) {
        return false;
    }

    return suggestions.every(isValidSuggestion);
};

export const isCustomChatAvatar = (
    avatar: MediaFragment | ReactNode
): avatar is ReactNode => {
    return isValidElement(avatar);
};
