import { StyledProps, Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
    isFromAssistant?: boolean;
    isSuggestion?: boolean;
}

const getColors = ({ theme, isFromAssistant }: { theme: Theme } & Props) => {
    if (
        (theme.palette.mode === 'dark' && isFromAssistant) ||
        (theme.palette.mode !== 'dark' && !isFromAssistant)
    ) {
        return css({
            /**
             * the component can be wrapped in a link,
             * !important to override the :visited color inherit
             */
            color: `${theme.palette.common.white} !important`,
            background: theme.palette.common.black,
        });
    }

    if (isFromAssistant) {
        return css({
            color: `${theme.palette.common.black} !important`,
            background: theme.palette.grey[100],
        });
    }

    return css({
        color: `${theme.palette.common.black} !important`,
        background: theme.palette.common.white,
    });
};

export const MessageBox = styled.div<StyledProps<Props>>`
    word-break: break-word;
    font: ${({ theme }) => theme.fonts.body};
    padding: ${({ theme }) => theme.spacing.xs};
    border-radius: ${({ theme }) => theme.shape.radius.xxs};
    opacity: ${({ isSuggestion }) => (isSuggestion ? 0.35 : 1)};
    ${getColors}
`;
