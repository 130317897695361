export const NON_OPAQUE_CLASSNAME = 'non-opaque';
const OPACITY = '0.7';

/**
 * Recursively applies the class 'non-opaque' to an HTML element and its descendants
 * up to a specified maximum depth.
 *
 * @param element - The HTML element to start the recursive process.
 * @param maxDepth - The maximum depth of recursion.
 */
export const applyNonOpaqueStyleRecursive = (
    element: HTMLElement | null,
    maxDepth: number = 20
): void => {
    if (!element || maxDepth <= 0) return;

    element.classList.add(NON_OPAQUE_CLASSNAME);

    (Array.from(element.children) as HTMLElement[]).forEach((child) => {
        applyNonOpaqueStyleRecursive(child, maxDepth - 1);
    });
};

/**
 * Recursively applies opacity styles to an HTML element and its descendants
 * based on the absence of the 'non-opaque' class up to a specified maximum depth.
 *
 * @param element - The HTML element to start the recursive process.
 * @param maxDepth - The maximum depth of recursion.
 */
export const applyOpacityStyleRecursive = (
    element: HTMLElement | null,
    maxDepth: number = 20
): void => {
    if (!element || maxDepth <= 0) return;

    const hasNonOpaqueInDescendants =
        element.querySelector(`.${NON_OPAQUE_CLASSNAME}`) !== null;
    const isNonOpaqueCurrentElement =
        element.classList.contains(NON_OPAQUE_CLASSNAME);

    if (!hasNonOpaqueInDescendants && !isNonOpaqueCurrentElement) {
        element.style.opacity = OPACITY;
    } else {
        (Array.from(element.children) as HTMLElement[]).forEach((child) => {
            applyOpacityStyleRecursive(child, maxDepth - 1);
        });
    }
};
