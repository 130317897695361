import InternalLink from '@components/utils/links/internalLink';
import MdText from '@components/utils/mdText';
import ConditionalWrapper from '@components/utils/wrappers/conditionalWrapper';
import { ROUTE_SUPPORT } from '@constants';
import { MessageRoleEnum } from '@hello-pimster/pimster-crm-sdk';
import { HStack } from '@lib/uikit';
import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';

import ChatAvatar, { ChatAvatar as ChatAvatarType } from './ChatAvatar';
import { MessageBox } from '../styles/MessageBox';
import { MessageBoxWrapper } from '../styles/MessageBoxWrapper';

interface ChatMessageProps {
    content: string;
    from: MessageRoleEnum;
    isSuggestion?: boolean;
    assistantAvatar?: ChatAvatarType;
    isLocked?: boolean;
    onClick?: () => void;
}

const ChatMessage = ({
    content,
    from,
    isSuggestion = false,
    assistantAvatar,
    isLocked = false,
    onClick,
}: ChatMessageProps) => {
    const { locale, query } = useRouter();
    const { product } = query;

    const href = useMemo(
        () => ({
            pathname: ROUTE_SUPPORT,
            query: { ...query, initialInput: content },
        }),
        [content, query]
    );

    const as = useMemo(
        () => `/${locale}/${product}/support`,
        [locale, product]
    );

    return (
        <MessageBoxWrapper isFromAssistant={from === MessageRoleEnum.Assistant}>
            <ConditionalWrapper
                wrapper={(children) => (
                    <InternalLink href={href} as={as} onClick={onClick}>
                        {children}
                    </InternalLink>
                )}
                hasToWrap={isSuggestion && !isLocked}
            >
                <HStack spacing='xs' alignItems='stretch'>
                    {assistantAvatar && from === MessageRoleEnum.Assistant && (
                        <ChatAvatar avatar={assistantAvatar} />
                    )}
                    <MessageBox
                        isFromAssistant={from === MessageRoleEnum.Assistant}
                        isSuggestion={isSuggestion}
                    >
                        {from === MessageRoleEnum.Assistant ? (
                            <MdText markdown={content} />
                        ) : (
                            content
                        )}
                    </MessageBox>
                </HStack>
            </ConditionalWrapper>
        </MessageBoxWrapper>
    );
};

export default memo(ChatMessage);
