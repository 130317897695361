import { IconName } from '@components/utils/icons/icon';
import {
    AiModuleFragment,
    MediaFragment,
    ModuleFragment,
} from '@graphql/generated/graphql';

export type ModuleIllustration =
    | { type: 'image'; image: MediaFragment }
    | { type: 'icon'; name: IconName };

export const getModuleIllustration = (
    module: NonNullable<ModuleFragment['Module']> | AiModuleFragment
): ModuleIllustration => {
    const { Image } = module;

    if (Image) {
        return {
            type: 'image',
            image: Image,
        };
    }

    return { type: 'icon', name: module.Icon as IconName };
};
