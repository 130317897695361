import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';
import StrapiImage from '@lib/image/strapiImage';
import { ModuleIllustration as TypeModuleIllustration } from '@utils/module/illustration';

import { ModuleIcon } from './ModuleIcon';

const LARGE_SIZE = '3rem';
const NORMAL_SIZE = '2rem';

type ModuleIllustrationSize = 'normal' | 'large';

export type ModuleIllustrationProps = TypeModuleIllustration & {
    size?: ModuleIllustrationSize;
    isLocked?: boolean;
};

const Image = styled(StrapiImage)<
    StyledProps<{ size: ModuleIllustrationSize }>
>`
    object-fit: cover;
    border-radius: 100%;
    border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
    height: ${({ size }) => (size === 'large' ? LARGE_SIZE : NORMAL_SIZE)};
    width: ${({ size }) => (size === 'large' ? LARGE_SIZE : NORMAL_SIZE)};
`;

export const ModuleIllustration = (props: ModuleIllustrationProps) => {
    if (props.type === 'icon') {
        const { type, ...rest } = props;
        return <ModuleIcon {...rest} />;
    }

    return (
        <Image
            src={props.image.url}
            alt={props.image.alternativeText}
            size={props.size}
            formats={props.image.formats}
            sizes={props.size === 'large' ? LARGE_SIZE : NORMAL_SIZE}
        />
    );
};
