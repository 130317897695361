import Icon from '@components/utils/icons/icon';
import InternalLink from '@components/utils/links/internalLink';
import LocaleSwitch from '@components/utils/localeSwitch';
import ConditionalWrapper from '@components/utils/wrappers/conditionalWrapper';
import { COMMON, ROUTE_SEARCH } from '@constants';
import { StyledProps, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { MediaFragment } from '@graphql/generated/graphql';
import StrapiImage from '@lib/image/strapiImage';
import { getStrapiAssetUrl } from '@lib/strapi';
import { motion } from 'framer-motion';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

type Props = {
    left?: LeftProps;
    center: CenterProps;
    locales?: string[];
    align?: 'center' | 'start';
    isTransparent?: boolean;
};

type ContainerProps = Pick<Props, 'align' | 'isTransparent'>;

type LeftProps =
    | {
          display: 'search';
      }
    | {
          display: 'back';
          backHref?: LinkProps['href'];
          backAs?: LinkProps['as'];
      };

type CenterProps =
    | {
          display: 'image';
          image: MediaFragment;
          href?: LinkProps['href'];
          as?: LinkProps['as'];
      }
    | {
          display: 'text';
          text: {
              title: string;
              subtitle?: string;
          };
      };

type ColorProps = { color: string };

const Container = styled(motion.div, {
    shouldForwardProp: (props) => props !== 'isTransparent',
})<StyledProps<ContainerProps>>`
    width: 100%;
    height: 100%;
    min-height: 4rem;
    display: grid;
    grid-template-columns: 1fr minmax(0, 4fr) 1fr;
    grid-template-areas: 'left center right';
    align-items: ${({ align }) => align};
    background-color: ${({ theme, isTransparent }) =>
        isTransparent ? 'transparent' : theme.palette.background.default};
    padding-block: ${({ theme }) => theme.spacing.xs};
    padding-inline: ${({ theme }) => theme.spacing.md};
    @media (min-width: 748px) {
        border-top-left-radius: ${({ theme }) => theme.shape.radius.default};
        border-top-right-radius: ${({ theme }) => theme.shape.radius.default};
    }
`;

const Left = styled.div`
    grid-area: left;
    justify-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Right = styled.div`
    grid-area: right;
    justify-self: end;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Center = styled.div`
    height: 100%;
    grid-area: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageContainer = styled.div`
    width: 8rem;
    height: 100%;
    overflow: hidden;
    position: relative;
`;

const Image = styled(StrapiImage)`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const PageTitle = styled.h1<StyledProps<ColorProps>>`
    font: ${(props) => props.theme.fonts.body};
    color: ${({ color }) => color};
`;

const PageSubtitle = styled.h2<StyledProps<ColorProps>>`
    font: ${(props) => props.theme.fonts.caption};
    color: ${({ color }) => color};
`;

const BackIcon = ({ color }: ColorProps) => (
    <Icon name='keyboard_arrow_left' color={color} size='medium' />
);

const SearchIcon = ({ color }: ColorProps) => (
    <Icon name='search' color={color} size='medium' />
);

const Header = ({
    left,
    center,
    locales,
    align = 'center',
    isTransparent = false,
}: Props) => {
    const { query } = useRouter();
    const { product } = query;
    const { t } = useTranslation(COMMON);
    const theme = useTheme();
    const color = isTransparent
        ? theme.palette.common.white
        : theme.palette.common.black;
    const subtitleColor = isTransparent
        ? theme.palette.common.white
        : theme.palette.text.secondary;

    return (
        <Container isTransparent={isTransparent} align={align}>
            {left && (
                <Left>
                    {left.display === 'back' && (
                        <InternalLink
                            href={
                                left.backHref || {
                                    pathname: '/[company]/[product]',
                                    query,
                                }
                            }
                            as={left.backAs || `/${product}`}
                            title={t('back')}
                        >
                            <BackIcon color={color} />
                        </InternalLink>
                    )}
                    {left.display === 'search' && (
                        <InternalLink
                            href={{
                                pathname: ROUTE_SEARCH,
                                query,
                            }}
                            as={`/${product}/search`}
                        >
                            <SearchIcon color={color} />
                        </InternalLink>
                    )}
                </Left>
            )}
            {center && (
                <Center>
                    {center.display === 'image' && (
                        <ConditionalWrapper
                            hasToWrap={Boolean(center.href && center.as)}
                            wrapper={(children: JSX.Element) => (
                                <Wrapper>
                                    <InternalLink
                                        href={center.href!}
                                        as={center.as!}
                                    >
                                        {children}
                                    </InternalLink>
                                </Wrapper>
                            )}
                        >
                            <ImageContainer>
                                <Image
                                    formats={center.image.formats}
                                    sizes='5rem'
                                    src={getStrapiAssetUrl(center.image)}
                                    alt={
                                        center.image.alternativeText ||
                                        'companyLogo'
                                    }
                                />
                            </ImageContainer>
                        </ConditionalWrapper>
                    )}
                    {center.display === 'text' && (
                        <TextContainer>
                            <PageTitle color={color}>
                                {center.text.title}
                            </PageTitle>
                            {center.text.subtitle && (
                                <PageSubtitle color={subtitleColor}>
                                    {center.text.subtitle}
                                </PageSubtitle>
                            )}
                        </TextContainer>
                    )}
                </Center>
            )}
            {locales && (
                <Right>
                    <LocaleSwitch subLocales={locales} />
                </Right>
            )}
        </Container>
    );
};

export default Header;
